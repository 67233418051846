.our-teams-container {
    display: flex;
    flex-flow: column wrap;
    gap:1vw;
    margin: 9vw 4vw;
    background-color:#fff ;
  }
  :target {
    padding-top: 8vw;
  }
/* 
  :link :visited :active {
    padding-top:0;
    scroll-padding-top:0;
  } */
  @media screen and (max-width:719px){

    .lpc-2{
      flex-wrap: wrap;
    }

    .lpc{
      flex-wrap: wrap-reverse;
    }
    
    .lpcdes,.lpcdes-2{

      flex-basis: unset;

      justify-content: center;

      align-self: center;

    }

    .leader-name{
      text-align: center;
      width: 100%;
    }

    .leader-position{
      text-align: center;
      width: 100%;
    }

    .leader-contents{
      align-self: center;
      text-align: center;
      width: 80%;
      margin: unset;
    }


  }
  @media screen and (min-width:601px) and (max-width:900px) {
    .our-teams-container {
      padding-top:8vw;
    }
  }

  @media screen and (max-width:600px){
    .our-teams-container {
      padding-top: 10vw;
    }
    .leadimage,.leadimage-2{
      width: 279.67px;
      height:235.97px;
    }
    .leader-contents{
      align-self: center;
      text-align: center;
      width: 80%;
      margin: unset;
      font-style: italic;
        font-size: .5vw;
        color:var(--info-text-color);
        text-align: center;
    }

  }