.main-activity-page {
  margin: 140px 0 40px 0;
  display: flex;
  flex-direction: column;
}

.divider-line {
  padding: 100px 0;
  border-bottom: 0px solid transparent; 
}

@media (max-width: 673px) {
  .main-activity-page {
    margin-top: 120px;
  }
  .divider-line {
    padding: 20px 0;
  }
}