.lpc{
    display: flex;
    justify-content: space-evenly;
    margin-left: 10%;
    margin-right: 10%;
    gap: 10%;
    align-items: center;
    margin-bottom: 7vw;


}

.lpcdes{
    flex-basis: 53%;
}

/* .lpcimg{
    flex-shrink: unset;
    display: flex;

} */
.leadimage{

    box-sizing: border-box;
    width: 27vw;
    height: 27vw;
    /* max-width: 33.16vw;
    max-height: 100%; */
    border: 8px solid var(--primary-dark);
    
}
@media  screen and (max-width:600px) {

.leadimage{
    width: 30vw;
    height: 25vw;
    
}
.lpc {
    margin-top: 2vw;
    margin-bottom: 10vw;
}
}