html {
  font-size: 16px;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;

  /* My styles */
  background-color:#fff;
  margin: 0;
  width: 100%;
  height: 100%;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: var(--secondary-text-color);
}


body::-webkit-scrollbar-thumb {
  background: var(--primary-dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button:hover {
  transform: scale(1.1);
}

button:active {
  transform: scale(1);  
}