.flipCard{
  border-radius: 0.9375vw;
  background-color: transparent;
  aspect-ratio: 6/7;
  height: auto;
  width: 20vw;
  display: flex;
  flex-direction: row;
  flex-basis: 33.333%;
}

.flipCard-inner {
  position: relative;
  border-radius: 0.9375vw;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 0.20833333333vw 0.41666666666vw 0 rgba(0,0,0,0.2);
}

.flipCard:hover .flipCard-inner {
  transform: rotateY(180deg);
}

.flipCard-front, .flipCard-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.9375vw;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flipCard-front {
  background-color: white;
  color: black;
}

.flipCard-back {
  display:flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  color: black;
  transform: rotateY(180deg);
}

.divider{
  width:70%;
  border-bottom: 0.10416666666vw  solid rgba(46, 36, 75, 0.3);
  margin-bottom: 0;
}

.divider-top{
  width: 70%;
  border-bottom: 0.05208333333vw solid rgba(38, 37, 64, 0.3);
}

.divider-bottom{
  width: 70%;
  border-bottom: 0.05208333333vw solid rgba(38, 37, 64, 0.3);
}

.cardName {
  padding-top: 0;
  margin-top: 1.2vw;
  color:var(--primary-text-color);
}
.cardName h2{
  font-size: 1.5vw;
  margin-top: 2%;
  margin-bottom: 4%;
}
.cardTitle {
  color:var(--secondary-text-color);
  font-size: 1.2vw;
}

.quoteText{
  padding-left: 20%;
  padding-right: 20%;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  font-size: 0.83333333333vw;
  padding-top: 5%;
  padding-bottom:5%;
}

.knowMoreBtn{
  background-color: transparent;
  box-sizing: border-box;
  width: 6.30208333333vw;
  height: 1.61458333333vw;
  border: 0.02604166666vw solid #262540;
  border-radius: 0.46875vw;
  font-size: 0.83333333333vw;
  font-weight: bold;
  font-style: italic;
  margin-top: 2.08333333333vw;
  cursor: pointer;
}


.memimg{
  height: auto;
  width: 15vw ;
  margin-top:4%;
}

