.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 70%;
  background-color: var(--background-color);
  border-radius: 4px;
  overflow: initial;
}

.modal-left {
  width: 30%;
  background-color: #f0f0f0;
  display: flex;
  flex-flow: column nowrap;
}

.modal-sections {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;
  padding: 16px;
  overflow-y: auto;
}

.modal-section {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;

}

.modal-section-active {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  background-color: var(--primary-dark);
  color: var(--background-color);
}

.modal-section:last-child {
  border-bottom: none;
}

.modal-right {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
}

.modal-close {
  position: absolute;
  bottom: 16px;
  right: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bolder;
  background-color: var(--primary-light);
  color: var(--primary-dark);
  cursor: pointer;
}

.modal-mobile-sections {
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bolder;
  background-color: var(--primary-light);
  color: var(--primary-dark);
  cursor: pointer;
}

.modal-mobile-nav {
  background-color: #f0f0f0;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  overflow-y: auto;
}

.modal-mobile-section {
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.modal-mobile-content {
  display: flex;
  flex-flow: column nowrap;
  padding: 1rem;
  overflow-y: auto;
}

@media (orientation: landscape) {
  .modal-mobile-sections {
    top: 0.9rem;
    left: 1rem;
  }

  .modal-close {
    bottom: 0.9rem;
    right: 1rem;
  }

  .modal-content {
    width: 90%;
    height: 65%;
  }
}