.nav {
  /* background: linear-gradient(
    180deg,
    rgba(191, 122, 48, 1) 0%,
    rgba(191, 122, 48, 0.3) 50%,
    rgba(191, 122, 48, 0.15) 65%,
    rgba(191, 122, 48, 0.075) 75.5%,
    rgba(191, 122, 48, 0.037) 82.85%,
    rgba(191, 122, 48, 0.019) 88%,
    rgba(191, 122, 48, 0) 100%
  ); */
  /* background-image: linear-gradient(
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.75) 50%,
    rgba(255, 255, 255, 0.5) 65%,
    rgba(255, 255, 255, 0.5) 75.5%,
    rgba(255, 255, 255, 0.5) 82.85%,
    rgba(255, 255, 255, 0.3) 88%,
    rgba(255, 255, 255, 0) 100%
  ); */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.6278712168461135) 75%,
    rgba(255, 255, 255, 0) 100%
  );
  display: flex;
  height: 10rem;
  width: 100vw;
  position: fixed;
  align-items: center;
  z-index: 1;
}

.nav ul {
  margin-left: 15vw;
  padding: 0;
  flex-direction: row;
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  align-items: baseline;
}

.nav li button {
  font-family: "Roboto";
  text-align: center;
  font-size: 18px;
  letter-spacing: 0;
  color: var(--primary-text-color);
  padding: 2rem 1.5rem;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  background-color: transparent;
  border: 0;
  padding-bottom: 2rem;
}

.nav li button:hover {
  color: var(--secondary-text-color);
  cursor: pointer;
}

.nav .action-items {
  flex:1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav .btn-filled {
  font-size: 18px;
  margin-right: 5vw;
  margin-left: auto;
  transition: all 0.3s ease 0s;
  /* margin-bottom: 1rem; */
}

.hamburger {
  display: flex;
  flex-direction: column;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  /* padding-bottom: 2rem; */
}

.hamburger span {
  display: block;
  width: 33px;
  height: 2px;
  margin-bottom: 5px;
  margin-right: 5vw;
  position: relative;
  background-color: var(--primary-dark);
  border-radius: 8px;
  transform-origin: 0 0;
  transition: 0.4s;
}

.hamburger.is-active span:nth-child(1) {
  transform: translate(0px, -2px) rotate(45deg);
  background-color: var(--secondary-text-color);
}

.hamburger.is-active span:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active span:nth-child(3) {
  transform: translate(-2px, 6px) rotate(-45deg);
  background-color: var(--secondary-text-color);
}

@media (max-width: 1301px) {
  .nav ul {
    display: none;
  }
}

@media (min-width: 1300px) {
  .hamburger {
    display: none;
  }
}

@media (max-width: 475px) {
  .nav .btn-filled {
    font-size: 13px;
  }
}

@media (max-width: 510px) {
  .nav {
    height: 7rem;
  }

  .nav .btn-filled {
    margin-bottom: 0;
  }

  .hamburger {
    padding-bottom: 0;
  }
}
