.activity-seperator {
  display: flex;
  margin: auto auto;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.activity-seperator::before,
.activity-seperator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid black;
  margin: 0 0.5em;
}

.activity-seperator span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 127%;
  color: var(--primary-text-color);
}

@media (max-width: 673px) {
  .activity-seperator span {
    font-size: 24px;
  }
}
