.main-activity-buttons-container {
  margin: auto auto;
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
}

.main-activity-buttons-container > button {
  width: 199px;
  height: 50px;
  margin: 1%;
  border-radius: 25px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--background-color);
  border-color: transparent;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  color: var(--secondary-text-color);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
}

.main-activity-buttons-container > button:hover {
  background: linear-gradient(
    134deg,
    rgba(136, 95, 136, 0.85) 1.18%,
    rgba(136, 95, 136, 0.85) 52.43%,
    rgba(46, 36, 75, 0.85) 98.61%
  );
  color: var(--background-color);
  border-radius: 25px;
}

@media (max-width: 673px) {
  .main-activity-buttons-container {
    display: none;
  }
}
