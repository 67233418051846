.landing-about-left-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image-container img {
  width: 70%;
  margin: 0.6vw 0;
  border: 0.4vw solid var(--tertiary-text-color);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.image-container .column-aligned-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: nowrap;
}

.image-container .column-aligned-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
  flex-wrap: nowrap;
}

.landing-about-right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-about-right-container .inner-heading {
  flex: 1;
  font-family: Roboto;
  margin-top: 0;
  font-size: 3.5vw;
  font-weight: 900;
  color: var(--primary-dark);
}

.landing-about-right-container .inner-text {
  flex: 1;
  font-family: Roboto;
  font-size: 1.3vw;
  /* font-weight: bold; */
  color: var(--info-text-color);
  line-height: 2rem;
  text-align: left;
  margin-bottom: 0;
}

@media (max-width: 1160px) {
  .landing-about-right-container .inner-heading {
    font-size: 3vw;
  }

  .landing-about-right-container .inner-text {
    font-size: 1.5vw;
    line-height: 2.4vw;
    word-spacing: 0.3vw;
  }
}

@media screen and (min-width: 768px) {
  .landing-about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15vw;
    /* height: 100vh; */
  }

  .landing-about-left-container .column-aligned-1 {
    margin-left: 5rem;
  }

  .landing-about-right-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
  }

  .landing-about-right-container .right-container {
    justify-content: flex-start;
    width: 70%;
    /* margin-right: 10rem; */
  }
}

@media (max-width: 767px) {
  .landing-about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10vw;
  }

  .landing-about-left-container .column-aligned-2 {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .landing-about-left-container {
    flex: 0.2;
    align-items: flex-start;
    justify-content: center;
  }

  .landing-about-right-container {
    flex: 1;
    width: 60vw;
    text-align: center;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .landing-about-right-container .inner-heading {
    font-family: Roboto;
    font-size: 4vw;
    color: var(--primary-dark);
    margin-top: 5vw;
  }

  .landing-about-right-container .inner-text {
    font-family: Roboto;
    font-size: 2vw;
    color: var(--info-text-color);
    line-height: 4vw;
    text-align: left;
  }

  .image-container {
    width: 70vw;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 425px) {
  .landing-about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20vw;
  }

  .landing-about-left-container {
    flex: 1.4;
    align-items: flex-end;
    justify-content: center;
  }

  .landing-about-right-container {
    flex: 1;
    width: 70vw;
    text-align: center;
  }

  .landing-about-right-container .inner-heading {
    font-family: Roboto;
    font-size: 1.5rem;
    font-weight: 900;
    color: var(--primary-dark);
    margin-top: 10vw;
  }

  .landing-about-right-container #sub-text {
    display: none;
  }

  .landing-about-right-container .inner-text {
    font-size: 0.9rem;
    line-height: 1.3rem;
    text-align: center;
  }

  .landing-about-left-container .image-container {
    width: 100vw;
  }
}
