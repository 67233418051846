.flexitemot{
  flex-basis: 33.3333333333%;
}
  .cards{
    row-gap: 1.5vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left:7.5vw;
    padding-right: 7.5vw;
    margin-top: 0;
  }


   @media screen and (max-width: 624px) {
      .cards {
                 
          justify-content: space-evenly;
          
          
      }
      .secc {
        margin-top: 1vw;
        margin-bottom: 2vw;
      }
      
    }

  
      
/*   
  @media screen and (max-width:1280px) {
  
  
      .flipCard{
    
          border-radius: 12px;
          background-color: transparent;
          aspect-ratio: 6/7;
          height: auto;
          width: 200px;
          display: flex;
          flex-direction: row;
        }
        
        .flipCard-inner {
          position: relative;
          border-radius: 12px;
          width: 100%;
          height: 100%;
          text-align: center;
          transition: transform 0.6s;
          transform-style: preserve-3d;
          box-shadow: 0 2.6666px 5.333333px 0 rgba(0,0,0,0.2);
        }
        
        .flipCard:hover .flipCard-inner {
          transform: rotateY(180deg);
        }
        
        .flipCard-front, .flipCard-back {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 12px;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
        }
        
        .flipCard-front {
          background-color: white;
          color: black;
        }
        
        .flipCard-back {
          display:flex;
          flex-direction: column;
          justify-content: center;
          background-color: white;
          color: black;
          transform: rotateY(180deg);
        }
        
        .divider{
          width:70%;
          border-bottom: 1.3333px solid rgba(46, 36, 75, 0.2);
        }
        
        .divider-top{
          width: 70%;
          border-bottom: .666666px solid rgba(38, 37, 64, 0.5);
        }
        
        .divider-bottom{
          width: 70%;
          border-bottom: .6666666px solid rgba(38, 37, 64, 0.5);
        }
        
        .cardTitle{
          color:var(--secondary-text-color);
          font-weight: 500;
          font-size: 10.6666666666px;
        }
        
        .cardName h2{
          margin-top: 0%;
          font-size: 16px;
        }
        .quoteText{
          text-align: justify;
          padding-left: 15%;
          padding-right: 15%;
          font-weight: bold;
          font-style: italic;
          font-size: 10.666666px;
          padding-top:10%;
          padding-bottom:10%;
        }
        
        .knowMoreBtn{
          background-color: transparent;
          box-sizing: border-box;
          width: 80.666666px;
          height: 20.666666px;
          border: 0.166666666px solid #262540;
          border-radius: 3px;
          font-size: 10.66666666px;
          font-weight: bold;
          font-style: italic;
          margin-top: 26.333333333px;
          cursor: pointer;
        }
        
        .memimg{
          width: 142.6666px ;
        } 
  
  
        /* .cards {
          margin-left: 6.666%;
          margin-right: 6.66%;
          display: flex;
          
          flex-wrap: wrap;
          justify-content: space-evenly;
          column-gap: 6.666%;
          flex-shrink: 1;
          
      } */
  
  
  /*
  @media screen and (min-width:1280px) {
    
    .cardName h2{
      font-size: 1.25vw;
      margin-top: 0%;
    }
  
    .cardTitle{
      color:var(--secondary-text-color);
      font-weight: 500;
      font-size: 0.83333333333vw;
    }
  }
  

  @media screen and (max-width:425px){

    .flexitemot{
      flex-basis: 100%;
    }
  }
  */
  /* @media screen and (max-width:768px){
    .cards{
      padding-top: 0%;
      padding-bottom: 0%;
    }
  } */
 
