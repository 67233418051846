.leaderDesc {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    line-height: 2.4vw;
    width:40%;
}
.leader-name {
    font-size: 2.2vw;
    color: var(--primary-text-color);
    margin-bottom: .4vw;
    margin-top: 0;
    font-weight: 500;
}
.leader-position {
    font-size:1.3vw;
    color:var(--secondary-text-color);
    margin-top: 0;
    font-weight: 500;
}
.leader-contents  {
    font-style: italic;
    font-size: .9vw;
    color:var(--info-text-color);
    text-align: left;
    line-height: 1.5vw;
}
.boldt {
    display: flex;
    font-style: italic;
    font-size: .9vw;
    color:var(--info-text-color);
    text-align: left;
    line-height: 1.5vw;
}
.simple-btn {
    display:none;
}
@media screen and (min-width:601px) and (max-width: 900px) {
    .leader-name {
        /* font-size: 3vw; */
        color: var(--primary-text-color);
        margin-top: 0;
        margin-bottom: .4vw;
        /* font-weight: 700; */
    }
    .leader-position {
        font-size:1.5vw;
        color:var(--secondary-text-color);
        margin-top: 1vw;
        font-weight: 500;
    }
    .leader-contents {
        font-size: 1.3vw;
    }
    .boldt {
        display: flex;
        font-size: 1.3vw;
        line-height: 1.5vw;
        text-align: left;
    }
}
@media screen and (max-width: 600px) {
    .leader-desc {
        padding-top:3vw;
    }
    .leader-name {
        font-size: 6vw;
        color: var(--primary-text-color);
        padding-top: 2.5vw;
        margin-bottom: .4vw;
        font-weight: 500;
    }
    .leader-position {
        font-size:3.5vw;
        color:var(--secondary-text-color);
        margin-top: 1vw;
        font-weight: 500;
    }
    .leader-contents  {
        display:none;
    }
    .boldt {
        display: flex;
        font-style: italic;
        font-size: 3vw;
        color:var(--info-text-color);
        text-align: center;
        line-height: 5vw;
    }
    .simple-btn {
        display: block;
        margin-top: 5vw;
        margin-bottom: 5vw;
        padding: 0.25rem 0.75rem;
  border-radius: 888em;
  background-color: #fff;
  border: 1px solid var(--primary-dark);
  color: var(--primary-dark);
  font-size: 3vw;
  font-weight: 500;
  cursor: pointer;
      }
  }