.campaign-fab-container {
  width: 62px;
  height: 62px;
  position: fixed;
  left: 92%;
  bottom: 20%;
  background: #ffffff;
  border-radius: 50%;
  box-sizing: border-box;
}

.campaign-fab-container > button {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 2px solid rgba(38, 37, 64, 0.16);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  cursor: pointer;
}

.campaign-fab-container img {
    margin: auto auto;
    width: 100%;
}

@media (max-width: 1024px) {
    .campaign-fab-container {
        width: 6vw;
        height: 6vw;
        min-width: 45px;
        min-height: 45px;
    }
}

@media (max-width: 771px) {
  .campaign-fab-container {
    left: 90%;
  }
}

@media (max-width: 673px) {
  .campaign-fab-container {
    left: 85vw;
    bottom: 15%;
    width: 10vw;
    height: 10vw;
    min-width: 40px;
    min-height: 40px;
  }
}

@media (max-width: 425px) {
    .campaign-fab-container {
        width: 12vw;
        height: 12vw;
        left: 82vw;
    }
}
