.main-heading {
  font-family: Roboto;
}
.main-HeadingText {
  color: var(--primary-text-color);
  text-align: center;
  font-size: 2.4vw;
  font-weight: bold;
  margin-bottom: 4vw;
}


@media (max-width: 900px) {
  .main-HeadingText {
    font-family: Roboto;
    font-size: 3vw;
    font-weight: 900;
    color: var(--primary-dark);
  }
}

@media (max-width: 600px) {
  .main-heading {
    padding-top: 10vw;
  }
  .main-HeadingText {
    font-family: Roboto;
    font-size: 7vw;
    font-weight: bold;
    color: var(--primary-dark);
  }
}
