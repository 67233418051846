.team-parent-container {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 20rem;
}

@media screen and (min-width: 768px) {
  .team-parent-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 15vw;
  }
}

@media screen and (max-width: 768px) and (min-width: 425px) {
  .team-parent-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10vw;
      height: 30rem;
  }
}

@media screen and (max-width: 425px) {
  .team-parent-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 20vw;
  }
}

.team-parent-header-container {
  flex: 0.2;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 2vw;
}

.team-parent-header-container > h1 {
  color: var(--primary-text-color);
  font-family: Roboto;
  font-weight: 900;
  font-size: 4vw;
}

@media (max-width: 673px) {
  .team-parent-header-container > h1 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    font-weight: 900;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .team-parent-container {
    justify-content: center;
    align-items: center;
    min-height: 30rem;
  }
}
