.footer-main-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 10vw;
}

.footer-contact-container {
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  gap: 0.4rem;
}

.footer-contact-container span {
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  color: var(--secondary-text-color);
}

.footer-contact-container span:first-child {
  color: var(--primary-text-color);
  padding-bottom: 1.1rem;
}

.footer-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-logo-container img {
  width: 3rem;
}

.footer-newsletter-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin: 0;
}

.footer-newsletter-container input {
  flex: 1;
  border-radius: 10px;
  /* margin-right: 2rem; */
  border: 1px solid var(--primary-text-color);
  padding: 0.2rem;
  color: var(--primary-text-color);
}

.footer-newsletter-container button {
  flex: 1;
  border-radius: 10px;
  padding: 0.2vw 1vw;
  background-color: var(--primary-dark);
  color: var(--primary-light)
}

.footer-newsletter-container button:hover {
  transform: scale(1);
}

.footer-action-items {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0.8;
  gap: 1rem;
}

.footer-logo-container img {
  cursor: pointer;
}

.footer-logo-container img:hover {
  transform: scale(1.2);
}

.footer-newsletter-container p {
  padding: 0;
  margin: 0;
}

.footer-newsletter-container p:nth-child(2) {
  padding-bottom: 0.1rem;
}


.footer-links-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding-bottom: 2rem;
}

.footer-links-container a:hover {
  color: var(--secondary-text-color);
  transform: translateY(-2px);
  cursor: pointer;
}

.footer-links-container a {
  color: var(--primary-text-color);
  font-size: 1rem;
  margin: 0.5vmax;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}


.footer-links-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.footer-links-container span:hover {
  color: var(--secondary-text-color);
  transform: translateY(-2px);
  cursor: pointer;
}

.footer-links-container span {
  color: var(--primary-text-color);
  font-size: 1rem;
  margin: 0.5vmax;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

@media (min-width: 768px) {
  .footer-container {
    display: flex;
    flex-direction: row;
    width: 95%;
    padding-bottom: 1rem;
  }

  .footer-contact-container {
    flex: 1;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
  }
  .footer-logo-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .footer-newsletter-container {
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    color: var(--info-text-color);
  }

  .footer-newsletter-container p {
    align-self: center;
  }
}

@media (max-width: 767px) {
  .footer-container {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
  }

  .footer-contact-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: space-evenly;
    order: 1;
    width: 85%;
  }

  .footer-newsletter-container {
    display: none;
  }

  .footer-logo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 70%;
    order: 2;
    padding-top: 2rem;
  }

  .footer-logo-container img {
    width: 2rem;
  }

  .footer-links-container {
    padding-top: 1rem;
  }
}

@media (max-width: 375px) {
  .footer-contact-container {
    font-size: 0.9rem;
  }
}
