.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9997;
    backdrop-filter: blur(5px) grayscale(50%);
}

.popup {
    position: relative;
    max-width: 90%;
}

@media (max-width: 767px) {
    .popup {
        max-width: 100%;
        padding: 10px;
    }
}
  